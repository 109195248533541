var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import GoldCup from 'root/src/client/web/giveaway/assets/ic-gold-cup.svg';
import SilverCup from 'root/src/client/web/giveaway/assets/ic-silver-cup.svg';
import Image from 'next/image';
export var CupSize;
(function (CupSize) {
    CupSize["SMALL"] = "small";
    CupSize["MEDIUM"] = "medium";
    CupSize["LARGE"] = "large";
})(CupSize || (CupSize = {}));
export var CupVariant;
(function (CupVariant) {
    CupVariant["Gold"] = "gold";
    CupVariant["Silver"] = "silver";
})(CupVariant || (CupVariant = {}));
const cupVariants = {
    [CupVariant.Gold]: {
        src: GoldCup.src,
        alt: 'Gold cup',
    },
    [CupVariant.Silver]: {
        src: SilverCup.src,
        alt: 'Silver cup',
    },
};
const cupSizeVariants = {
    [CupSize.SMALL]: {
        width: 30,
        height: 30,
    },
    [CupSize.MEDIUM]: {
        width: 60,
        height: 60,
    },
    [CupSize.LARGE]: {
        width: 90,
        height: 90,
    },
};
export const Cup = (_a) => {
    var { variant, size = CupSize.SMALL } = _a, props = __rest(_a, ["variant", "size"]);
    const { src, alt } = cupVariants[variant];
    const { width, height } = cupSizeVariants[size];
    return <Image width={width} height={height} {...props} src={src} alt={alt}/>;
};
