export function getSliderBreakpoints(width) {
    if (width < 600)
        return 1;
    if (width < 840)
        return 2;
    if (width < 1080)
        return 3;
    if (width < 1440)
        return 4;
    if (width < 2082)
        return 5;
    return 8;
}
