var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useAppDispatch, useAppSelector } from 'root/src/client/logic/helpers/useStore';
import apiRequest from 'root/src/client/logic/api/thunks/apiRequest';
import { ActionStrategyId } from '@daredrop-com/rewards-api-contract';
import { giveawayAnalyticsEvents, giveawayEndpointIds, } from 'root/src/shared/giveaways/giveaways.constants';
import { authApiDomain } from 'root/src/shared/constants/authApi';
import { getCookie } from 'root/src/client/v2/browser/helpers/cookies';
import { apiGatewayEndpoint } from 'root/src/shared/constants/aws';
import analyticsSendItem from 'root/src/client/logic/analytics/util/analyticsSendItem';
import { useUserAuthentication } from 'root/src/client/v2/common/hooks/useUser';
import creatorDataHof from 'root/src/client/logic/creatorData/util/creatorDataHof';
const entryEventsMap = new Map([
    [ActionStrategyId.EnterGiveawayTwitch, giveawayAnalyticsEvents.SUBMIT_ENTRY_TWITCH],
    [ActionStrategyId.EnterGiveawayYoutube, giveawayAnalyticsEvents.SUBMIT_ENTRY_YOUTUBE],
]);
const entryEventsSuccessMap = new Map([
    [ActionStrategyId.EnterGiveawayTwitch, giveawayAnalyticsEvents.SUBMIT_ENTRY_TWITCH_SUCCESS],
    [ActionStrategyId.EnterGiveawayYoutube, giveawayAnalyticsEvents.SUBMIT_ENTRY_YOUTUBE_SUCCESS],
]);
export const useGiveawayApi = () => {
    const { userId } = useUserAuthentication();
    const dispatch = useAppDispatch();
    const targetUserId = useAppSelector(creatorDataHof('userId'));
    const createGiveaway = (params) => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield dispatch(apiRequest(giveawayEndpointIds.CREATE_GIVEAWAY, {
            repeatTimes: params.repeatTimes,
            durationTime: params.durationTime,
        }));
        analyticsSendItem(giveawayAnalyticsEvents.CREATE_GIVEAWAY, userId);
        return res;
    });
    const createSchedule = (params) => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield dispatch(apiRequest(giveawayEndpointIds.CREATE_GIVEAWAY_SCHEDULE, {
            repeatTimes: params.repeatTimes,
            durationTime: params.durationTime,
        }));
        if (!res.error) {
            analyticsSendItem(giveawayAnalyticsEvents.CREATE_GIVEAWAY_SCHEDULE, userId);
        }
        return res;
    });
    const createEntry = (params) => __awaiter(void 0, void 0, void 0, function* () {
        const eventName = entryEventsMap.get(params.strategyId);
        if (eventName) {
            analyticsSendItem(eventName, userId);
        }
        const result = yield dispatch(apiRequest(giveawayEndpointIds.CREATE_GIVEAWAY_ENTRY, {
            actionId: params.actionId,
            giveawayId: params.giveawayId,
        }));
        if (!result.error) {
            const eventSuccessName = entryEventsSuccessMap.get(params.strategyId);
            if (eventSuccessName) {
                analyticsSendItem(eventSuccessName, userId);
            }
        }
        return result;
    });
    const redeemVoucher = (params) => __awaiter(void 0, void 0, void 0, function* () {
        analyticsSendItem(giveawayAnalyticsEvents.REDEEM_VOUCHER, userId);
        return dispatch(apiRequest(giveawayEndpointIds.REDEEM_REWARD, {
            rewardId: params.rewardId,
            voucherId: params.voucherId,
        }));
    });
    const submitSteamEntry = (params) => __awaiter(void 0, void 0, void 0, function* () {
        analyticsSendItem(giveawayAnalyticsEvents.SUBMIT_STEAM_ENTRY, userId);
        const url = new URL(`https://${authApiDomain}/sign-in/steam`);
        const state = encodeURIComponent(JSON.stringify({
            actionId: params.actionId,
            giveawayId: params.giveawayId,
            redirectURL: `${apiGatewayEndpoint}${giveawayEndpointIds.CREATE_GIVEAWAY_ENTRY_CALLBACK}`,
            fallbackURL: window.location.href,
            token: getCookie('accessToken'),
        }));
        url.searchParams.set('state', state);
        window.location.replace(url);
    });
    const enterGiveaway = (params) => __awaiter(void 0, void 0, void 0, function* () {
        analyticsSendItem(giveawayAnalyticsEvents.SUBMIT_ENTER_GIVEAWAY, userId);
        return dispatch(apiRequest(giveawayEndpointIds.ENTER_GIVEAWAY, {
            giveawayId: params.giveawayId,
        }));
    });
    const isGiveawayActionDone = (params) => __awaiter(void 0, void 0, void 0, function* () {
        return dispatch(apiRequest(giveawayEndpointIds.IS_GIVEAWAY_ACTION_DONE, {
            giveawayId: params.giveawayId,
            actionId: params.actionId,
        }));
    });
    const saveGiveawayTerms = () => __awaiter(void 0, void 0, void 0, function* () {
        return dispatch(apiRequest(giveawayEndpointIds.SAVE_GIVEAWAY_TERMS));
    });
    const fetchVouchers = (status) => {
        return dispatch(apiRequest(giveawayEndpointIds.GET_USER_VOUCHERS, {
            userId: targetUserId,
            status,
        }));
    };
    const editSchedule = (params) => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield dispatch(apiRequest(giveawayEndpointIds.UPDATE_USER_GIVEAWAY_SCHEDULER, {
            id: params.id,
            repeatTimes: params.repeatTimes,
            durationTime: params.durationTime,
            status: params.status,
        }));
        if (!res.error) {
            analyticsSendItem(giveawayAnalyticsEvents.EDIT_GIVEAWAY_SCHEDULE, userId);
        }
        return res;
    });
    return {
        createGiveaway,
        createEntry,
        redeemVoucher,
        submitSteamEntry,
        enterGiveaway,
        isGiveawayActionDone,
        saveGiveawayTerms,
        fetchVouchers,
        editSchedule,
        createSchedule,
    };
};
