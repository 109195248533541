import { useAppDispatch, useAppSelector, } from 'root/src/client/logic/helpers/useStore';
import apiRequest from 'root/src/client/logic/api/thunks/apiRequest';
import { giveawayEndpointIds } from 'root/src/shared/giveaways/giveaways.constants';
import creatorDataHof from 'root/src/client/logic/creatorData/util/creatorDataHof';
export const useCreatorDataApi = () => {
    const dispatch = useAppDispatch();
    const userId = useAppSelector(creatorDataHof('userId'));
    const fetchGiveaways = () => {
        return dispatch(apiRequest(giveawayEndpointIds.GET_USER_GIVEAWAYS, {
            userId,
        }));
    };
    const fetchRewards = () => {
        return dispatch(apiRequest(giveawayEndpointIds.GET_USER_GIVEAWAY_REWARDS, {
            userId,
        }));
    };
    const fetchScheduler = () => {
        return dispatch(apiRequest(giveawayEndpointIds.GET_USER_ACTIVE_GIVEAWAY_SCHEDULER, {
            userId,
        }));
    };
    return {
        fetchGiveaways,
        fetchRewards,
        fetchScheduler,
    };
};
