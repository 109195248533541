var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/image';
import classnames from 'classnames';
import { twMerge } from 'tailwind-merge';
export const Root = (_a) => {
    var { children, className } = _a, props = __rest(_a, ["children", "className"]);
    return (<div className={classnames('group bg-black flex-initial px-4 pt-4 pb-6 w-56 rounded flex flex-col justify-center gap-1', className)} {...props}>
			{children}
		</div>);
};
export const ImageContainer = (_a) => {
    var { children, className } = _a, props = __rest(_a, ["children", "className"]);
    return (<div className={twMerge('rounded border-2 h-48 w-48 border-solid border-teal relative flex flex-initial', className)} {...props}>
			{children}
		</div>);
};
export const GameImage = (_a) => {
    var { className, src } = _a, props = __rest(_a, ["className", "src"]);
    return (<Image {...props} src={src} layout="fill" className={twMerge('brightness-50', className)}/>);
};
export const GameTitle = (_a) => {
    var { children, className } = _a, props = __rest(_a, ["children", "className"]);
    return (<span className={classnames('text-lg truncate leading-5', className)} {...props}>
			{children}
		</span>);
};
export const RewardTitle = (_a) => {
    var { children, className } = _a, props = __rest(_a, ["children", "className"]);
    return (<span className={classnames(className, 'text-teal text-sm')} {...props}>
			{children}
		</span>);
};
export const Details = (_a) => {
    var { children, className } = _a, props = __rest(_a, ["children", "className"]);
    return (<div className={classnames('flex flex-col gap-2', className)} {...props}>
			{children}
		</div>);
};
export const PlatformIcon = (_a) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (<FontAwesomeIcon className={classnames('self-start', className)} size="2x" {...props}/>);
};
export const ClaimedLabel = (_a) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (<div className={classnames('absolute z-10 top-[10px] bg-orange-1 px-[8px] py-[6px] text-[10px] tracking-[0.02rem] text-black rounded-[5px] font-[400]', className)} {...props}>
			CLAIMED
		</div>);
};
export const RewardRestrictions = (_a) => {
    var { children, className } = _a, props = __rest(_a, ["children", "className"]);
    return (<span className={classnames('text-red-500 text-xs', className)} {...props}>
			{children}
		</span>);
};
