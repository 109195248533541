import { useAppSelector } from 'root/src/client/logic/helpers/useStore';
import { GiveawayStatus, } from '@daredrop-com/rewards-api-contract';
import currentRouteParamsRecordId from 'root/src/client/logic/route/selectors/currentRouteParamsRecordId';
import { giveawayReward, giveawayScheduler, giveawayTerms, giveawayVouchers, } from 'root/src/shared/descriptions/endpoints/recordTypes';
export const useCreatorGiveaways = () => {
    var _a, _b;
    const recordId = useAppSelector(currentRouteParamsRecordId);
    return (_b = (_a = useAppSelector(state => { var _a, _b, _c; return (_c = (_b = (_a = state === null || state === void 0 ? void 0 : state.api) === null || _a === void 0 ? void 0 : _a.records) === null || _b === void 0 ? void 0 : _b.giveaway) === null || _c === void 0 ? void 0 : _c[recordId]; })) === null || _a === void 0 ? void 0 : _a.giveaways) !== null && _b !== void 0 ? _b : [];
};
export const useCreatorActiveGiveaway = () => {
    const giveaways = useCreatorGiveaways();
    return giveaways === null || giveaways === void 0 ? void 0 : giveaways.findLast(giveaway => giveaway.status === GiveawayStatus.Active);
};
export const useCreatorResolvedGiveaway = () => {
    var _a, _b;
    const giveaways = useCreatorGiveaways();
    return (_b = (_a = giveaways === null || giveaways === void 0 ? void 0 : giveaways.filter(giveaway => giveaway.status === GiveawayStatus.Resolved)) === null || _a === void 0 ? void 0 : _a.sort((a, b) => new Date(b.endDate).getTime() - new Date(a.endDate).getTime())) === null || _b === void 0 ? void 0 : _b.at(0);
};
export const useUserVouchers = () => {
    var _a;
    const recordId = useAppSelector(currentRouteParamsRecordId);
    return (_a = useAppSelector(state => { var _a, _b, _c; return (_c = (_b = (_a = state === null || state === void 0 ? void 0 : state.api) === null || _a === void 0 ? void 0 : _a.records) === null || _b === void 0 ? void 0 : _b[giveawayVouchers]) === null || _c === void 0 ? void 0 : _c[recordId]; })) !== null && _a !== void 0 ? _a : [];
};
export const useCreatorRewards = () => {
    const recordId = useAppSelector(currentRouteParamsRecordId);
    return useAppSelector(state => { var _a, _b, _c; return (_c = (_b = (_a = state === null || state === void 0 ? void 0 : state.api) === null || _a === void 0 ? void 0 : _a.records) === null || _b === void 0 ? void 0 : _b[giveawayReward]) === null || _c === void 0 ? void 0 : _c[recordId]; }) || [];
};
export const useGiveawayTerms = () => {
    return useAppSelector(state => { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.userData) === null || _b === void 0 ? void 0 : _b[giveawayTerms]; });
};
export const useCreatorGiveawayScheduler = () => {
    const recordId = useAppSelector(currentRouteParamsRecordId);
    return useAppSelector(state => { var _a, _b, _c; return (_c = (_b = (_a = state === null || state === void 0 ? void 0 : state.api) === null || _a === void 0 ? void 0 : _a.records) === null || _b === void 0 ? void 0 : _b[giveawayScheduler]) === null || _c === void 0 ? void 0 : _c[recordId]; });
};
